import React, { useEffect, useState } from "react";
import "./CouponTInfo.css";
const CoupounTInfo = ({ data }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const language = data?.data?.language;
  const countries = data?.pluckCountries;
  const discountPercentage = data?.data?.coupons?.discount_percentage;

  return (
    <div className="CoupounTInfo">
      <div className="info">
        <div className="info_line">
          {isMobile ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M14 11C14 11.5304 13.7893 12.0391 13.4142 12.4142C13.0392 12.7893 12.5304 13 12 13C11.4696 13 10.9609 12.7893 10.5858 12.4142C10.2107 12.0391 10 11.5304 10 11C10 9.93912 10.4214 8.92171 11.1716 8.17156C11.9217 7.42142 12.9391 6.99999 14 6.99999C15.0609 6.99999 16.0783 7.42142 16.8284 8.17156C17.5786 8.92171 18 9.93912 18 11C18 12.5913 17.3679 14.1174 16.2427 15.2426C15.1174 16.3678 13.5913 17 12 17C10.4087 17 8.88259 16.3678 7.75737 15.2426C6.63215 14.1174 6.00001 12.5913 6.00001 11C6.00001 8.87826 6.84287 6.84342 8.34316 5.34313C9.84345 3.84284 11.8783 2.99999 14 2.99999C16.1217 2.99999 18.1566 3.84284 19.6569 5.34313C21.1572 6.84342 22 8.87826 22 11C22 13.6522 20.9464 16.1957 19.0711 18.0711C17.1957 19.9464 14.6522 21 12 21C9.34785 21 6.80431 19.9464 4.92894 18.0711C3.05358 16.1957 2.00001 13.6522 2.00001 11C1.99633 8.39358 2.84631 5.85768 4.42001 3.77999C4.57324 3.55401 4.77111 3.3618 5.00144 3.21519C5.23177 3.06859 5.48967 2.97069 5.75927 2.92754C6.02887 2.88439 6.30444 2.8969 6.56902 2.96429C6.8336 3.03168 7.08158 3.15253 7.29769 3.31939C7.51379 3.48626 7.69344 3.6956 7.82557 3.93453C7.9577 4.17346 8.0395 4.43691 8.06595 4.70865C8.0924 4.9804 8.06293 5.25468 7.97936 5.5146C7.89579 5.77452 7.75988 6.01458 7.58001 6.21999"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path
                d="M23.3334 18.3333C23.3334 19.2174 22.9822 20.0652 22.3571 20.6903C21.732 21.3155 20.8841 21.6666 20.0001 21.6666C19.116 21.6666 18.2682 21.3155 17.643 20.6903C17.0179 20.0652 16.6667 19.2174 16.6667 18.3333C16.6667 16.5652 17.3691 14.8695 18.6193 13.6193C19.8696 12.369 21.5653 11.6666 23.3334 11.6666C25.1015 11.6666 26.7972 12.369 28.0474 13.6193C29.2977 14.8695 30.0001 16.5652 30.0001 18.3333C30.0001 20.9855 28.9465 23.529 27.0711 25.4044C25.1958 27.2797 22.6522 28.3333 20.0001 28.3333C17.3479 28.3333 14.8044 27.2797 12.929 25.4044C11.0536 23.529 10.0001 20.9855 10.0001 18.3333C10.0001 14.7971 11.4048 11.4057 13.9053 8.90522C16.4058 6.40474 19.7972 4.99998 23.3334 4.99998C26.8696 4.99998 30.261 6.40474 32.7615 8.90522C35.262 11.4057 36.6667 14.7971 36.6667 18.3333C36.6667 22.7536 34.9108 26.9928 31.7852 30.1184C28.6596 33.244 24.4203 35 20.0001 35C15.5798 35 11.3406 33.244 8.21495 30.1184C5.08934 26.9928 3.33339 22.7536 3.33339 18.3333C3.32725 13.9893 4.74389 9.76281 7.36673 6.29998C7.6221 5.92335 7.95189 5.603 8.33577 5.35865C8.71965 5.11431 9.14949 4.95115 9.59882 4.87924C10.0481 4.80732 10.5074 4.82816 10.9484 4.94048C11.3894 5.0528 11.8027 5.25422 12.1628 5.53232C12.523 5.81043 12.8224 6.15934 13.0427 6.55755C13.2629 6.95576 13.3992 7.39485 13.4433 7.84775C13.4874 8.30066 13.4383 8.7578 13.299 9.191C13.1597 9.62421 12.9332 10.0243 12.6334 10.3666"
                stroke="black"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          {
            language === 'en' ? 'Effective' : "فعال"
          }
          
        </div>
        <div className="info_line">
          {isMobile ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M15 9L9 15M9 9H9.01M15 15H15.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path
                d="M24.9999 15L14.9999 25M14.9999 15H15.0166M24.9999 25H25.0166M36.6666 20C36.6666 29.2047 29.2047 36.6666 19.9999 36.6666C10.7952 36.6666 3.33325 29.2047 3.33325 20C3.33325 10.7952 10.7952 3.33331 19.9999 3.33331C29.2047 3.33331 36.6666 10.7952 36.6666 20Z"
                stroke="black"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          {discountPercentage && `${discountPercentage}% ${language === 'en' ? 'off' : 'خصم'}`}
        </div>
        <div className="info_line">
          {isMobile ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M8.835 14H5C4.79653 14.0111 4.60131 14.0841 4.44046 14.2092C4.27962 14.3343 4.16083 14.5055 4.1 14.7L2.1 20.7C2 20.8 2 20.9 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.9 22 20.8 21.9 20.7L19.9 14.7C19.8392 14.5055 19.7204 14.3343 19.5595 14.2092C19.3987 14.0841 19.2035 14.0111 19 14H15.165M18 8C18 12.5 12 17 12 17C12 17 6 12.5 6 8C6 6.4087 6.63214 4.88258 7.75736 3.75736C8.88258 2.63214 10.4087 2 12 2C13.5913 2 15.1174 2.63214 16.2426 3.75736C17.3679 4.88258 18 6.4087 18 8ZM14 8C14 9.10457 13.1046 10 12 10C10.8954 10 10 9.10457 10 8C10 6.89543 10.8954 6 12 6C13.1046 6 14 6.89543 14 8Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path
                d="M14.725 23.3333H8.33337C7.99426 23.3518 7.66889 23.4734 7.40081 23.6819C7.13274 23.8904 6.93476 24.1759 6.83337 24.5L3.50004 34.5C3.33337 34.6666 3.33337 34.8333 3.33337 35C3.33337 36 4.00004 36.6666 5.00004 36.6666H35C36 36.6666 36.6667 36 36.6667 35C36.6667 34.8333 36.6667 34.6666 36.5 34.5L33.1667 24.5C33.0653 24.1759 32.8673 23.8904 32.5993 23.6819C32.3312 23.4734 32.0058 23.3518 31.6667 23.3333H25.275M30 13.3333C30 20.8333 20 28.3333 20 28.3333C20 28.3333 10 20.8333 10 13.3333C10 10.6811 11.0536 8.13761 12.929 6.26225C14.8043 4.38688 17.3479 3.33331 20 3.33331C22.6522 3.33331 25.1957 4.38688 27.0711 6.26225C28.9465 8.13761 30 10.6811 30 13.3333ZM23.3334 13.3333C23.3334 15.1743 21.841 16.6666 20 16.6666C18.1591 16.6666 16.6667 15.1743 16.6667 13.3333C16.6667 11.4924 18.1591 9.99998 20 9.99998C21.841 9.99998 23.3334 11.4924 23.3334 13.3333Z"
                stroke="black"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          {/* {data?.coupons?.countries?.map((country)=>( */}
          <span>{countries}</span>
          {/* ))} */}
        </div>
      </div>
    </div>
  );
};

export default CoupounTInfo;
