import React from "react";
import "./FooterFive.css";
const FooterFive = (props) => {
  return (
    <footer className="footerFive">
      <ul>
        <li>
          <a href="##">
            {
              props.lang === 'ar' ? "سياسة الخصوصية" : 'privacy policy'
            }
          </a>
        </li>
        <li>
          <a href="##">
          {
              props.lang === 'ar' ? "شروط الاستخدام" : 'Terms and conditions'
            }
            
          </a>
        </li>
      </ul>
      {
              props.lang === 'ar' ? <p>©جميع الحقوق محفوظة</p> : <p>©all right reserved</p>
            }
      
    </footer>
  );
};

export default FooterFive;
